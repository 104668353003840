@font-face {
  font-family: 'NeuzeitSLTStd-BookHeavy';
  src: local('NeuzeitSLTStd-BookHeavy'), url(./Assets/Fonts/NeuzeitSLTStd-BookHeavy.ttf) format('opentype');
}

@font-face {
  font-family: 'muli-light';
  src: local('muli.light'), url(./Assets/Fonts/muli.light.ttf) format('truetype');
}

@font-face {
  font-family: 'muli-regular';
  src: local('muli.regular'), url(./Assets/Fonts/muli.regular.ttf) format('truetype');
}

@font-face {
  font-family: 'wordpress';
  src: local('wordpress'), url(./Assets/Fonts/wordpress.ttf) format('truetype');
}

@font-face {
  font-family: 'firebase';
  src: local('firebase'), url(./Assets/Fonts/ProductSans-Regular.ttf) format('truetype');
}